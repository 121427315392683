var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-8 col-md-8 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center"
  }, [_vm._v("Tandatangan Penerima")]), _c('div', {
    staticClass: "row mb-2"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('div', {
    staticClass: "table-responsive p-2 mt-3 mb-4"
  }, [_c('table', {
    staticClass: "table table-sm"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v(_vm._s(_vm.$t("name")))]), _c('th', [_vm._v(_vm._s(_vm.$t("mykad")))]), _c('th', [_vm._v(_vm._s(_vm.$t("relationship")))]), _c('th', [_vm._v(_vm._s(_vm.$t("email")))]), _c('th', [_vm._v(_vm._s(_vm.$t("mobile-no")))]), _c('th')])]), _c('tbody', [_vm._l(_vm.receivers, function (receiver, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(receiver.name))]), _c('td', [_vm._v(_vm._s(receiver.ic_number))]), _c('td', [_vm._v(_vm._s(receiver.relationship))]), _c('td', [_vm._v(_vm._s(receiver.email))]), _c('td', [_vm._v(_vm._s(receiver.mobile_number))]), _c('td', {
      staticClass: "text-right"
    }, [_c('div', {
      staticClass: "btn-group-vertical",
      attrs: {
        "role": "group",
        "aria-label": "Vertical button group"
      }
    }, [_c('button', {
      staticClass: "btn btn-primary btn-sm",
      on: {
        "click": function ($event) {
          return _vm.modalsignature(receiver.id);
        }
      }
    }, [_vm._v(" Signature ")])])])]);
  }), _vm.receivers && _vm.receivers.length == 0 ? _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "6"
    }
  }, [_c('h5', {
    staticClass: "mt-5 mb-5"
  }, [_vm._v(_vm._s(_vm.$t("no-data")))])])]) : _vm._e()], 2)])])])])])]), _c('div', {
    staticClass: "text-right mt-3"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save-next")) + " ")])]), _c('b-modal', {
    ref: "mmodalSign",
    staticClass: "modal fade",
    attrs: {
      "id": "modal-1",
      "title": "Tambah Tandatangan Penerima",
      "ok-title": "Simpan",
      "cancel-title": "Batal",
      "no-enforce-focus": true
    },
    on: {
      "ok": _vm.addsign,
      "cancel": _vm.hidemmodalSign
    }
  }, [_c('div', {
    staticClass: "row mt-3"
  }, [_c('div', {
    staticClass: "col-md-12 text-center"
  }, [!_vm.signature ? _c('div', {
    staticClass: "card",
    staticStyle: {
      "width": "400px",
      "display": "inline-block"
    }
  }, [_c('div', {
    staticStyle: {
      "position": "relative",
      "height": "300px",
      "width": "100%"
    }
  }, [_c('VueSignaturePad', {
    ref: "signaturePad",
    staticStyle: {
      "position": "absolute",
      "z-index": "1"
    },
    attrs: {
      "height": "300px",
      "width": "500px",
      "options": {
        onBegin: function () {
          _vm.$refs.signaturePad.resizeCanvas();
        }
      }
    }
  }), _c('label', {
    staticStyle: {
      "z-index": "0",
      "position": "absolute",
      "right": "0px",
      "left": "0px",
      "top": "45%",
      "color": "#b6b6b4"
    }
  }, [_vm._v(" Tandatangan disini ")]), _c('a', {
    staticClass: "btn btn-primary",
    staticStyle: {
      "position": "absolute",
      "right": "5px",
      "top": "5px",
      "z-index": "2"
    },
    on: {
      "click": _vm.undo
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/rubber.svg",
      "height": "20",
      "alt": ""
    }
  })])], 1)]) : _vm._e(), _c('img', {
    staticClass: "mt-3",
    staticStyle: {
      "width": "300px"
    },
    attrs: {
      "src": _vm.signature,
      "alt": "",
      "srcset": ""
    }
  })])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }